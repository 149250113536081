<template>
  <overlay v-if="isOpen">
    <credpal-loader>
      {{ currentSlide }}
    </credpal-loader>
  </overlay>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'InstantApproval',
  data() {
    return {
      form: this.$options.basicForm(),
      isOpen: false,
      slide: {
        texts: ['Initializing...', 'Validating...', 'Processing...'],
        index: 0,
        interval: 10000,
        timeout: null,
      },
    };
  },
  computed: {
    currentSlide() {
      return this.slide.texts[this.slide.index];
    },
  },
  mounted() {
    this.$emit('mounted');
  },
  methods: {
    ...mapActions('session', ['updateUser']),
    beginSlide() {
      this.slide.index = 0;

      this.next();
    },
    close() {
      this.isOpen = false;
    },
    displayErrorAlert() {
      this.$error({
        title: 'Approval in progress',
        body: `Your account is being processed, you should get a response shortly.`,
        button: 'Continue',
        onClose: () => {
          this.emitCompletedEvent();
        }
      });
    },
    determineAlert(user) {
      const { loanbot_status: loanbotStatus } = user;
      switch (loanbotStatus) {
        case 'approved':
          this.displayApprovedAlert(user);
          break;
        case 'failed':
          this.displayErrorAlert();
          break;
        case 'undecided':
          this.emitCompletedEvent();
          break;
        case 'declined':
        default:
          this.emitCompletedEvent();
          break;
      }
    },
    displayApprovedAlert({ profile }) {
      const formattedCreditLimitAmount = this.$options.filters.nearestWhole(profile.credit_limit);
      const formattedLoanLimit = this.$options.filters.nearestWhole(profile.standard_loan_limit);
      const bold = (text) => `<strong class="font-bold">${text}</strong>`
      this.$success({
        title: 'Approved!',
        body: `You have been approved for a ${bold(formattedCreditLimitAmount)} credit limit and a ${bold(formattedLoanLimit)} loan limit.`,
        button: 'Continue',
        onClose: () => {
          this.emitCompletedEvent();
        }
      });
    },
    emitCompletedEvent() {
      this.$emit('complete-instant-verification');
    },
    open() {
      if (!this.isOpen) {
        this.processInstantApproval();
      }
      this.isOpen = true;
    },
    next() {
      this.slide.timeout = setTimeout(() => {
        if (this.slide.index >= this.slide.texts.length - 1) {
          return clearTimeout(this.slide.timeout);
        }
        this.slide.index++;
        this.next();
      }, this.slide.interval);
    },
    async processInstantApproval() {
      this.beginSlide();

      this.form.error = null;
      this.form.setLoading();
      await this.sendRequest('personal.onboarding.instantApproval', {
        success: (response) => {
          const { data: { user, profile } } = response.data;
          this.updateUser({ ...user, profile })
            .then(() => {
              this.close();
              this.determineAlert(user);
            });
        },
        error: (error) => {
          this.form.error = error;
          this.close();
          this.displayErrorAlert();
        }
      });
      this.form.setLoading(false);
    },
  },
};
</script>
